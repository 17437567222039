
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import api from "@/api/axios";
import Toast from "@/components/UI/Toast";
export default defineComponent({
  name: "RedPackage",
  emits:['close'],
  props: {
    src: {
      type: String,
      default: "",
    },

    target: {
      type: String,
      default: "",
    },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, ctx) {
    const router = useRouter();
    function onOpenPackage() {
      const fData = new FormData();
      fData.append("returnurl", 'http://m.bzwz.com/wechatred');
      api
        .post("/M/Event/redPacketWeChatUrl", fData)
        .then((res) => {
          if (res.data.success) {
            window.location.href = res.data.returnUrl;
          } else {
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          console.log('false false')
        });
    }
    function jumpRed() {

      const token = localStorage.getItem("token") || "";
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        close();
        router.push({
          name: "Introduction"
        });
        return false;

        // window.location.href = 'http://bzwz.com/myInvitation';


    }
    function close() {
       ctx.emit('close')
    }
    return {
      onOpenPackage,
      close,
      jumpRed
    };
  },
});
