<template>
<!--<div v-if="advise && list" @click="jumpRed">
    <img style="position: fixed;left: 0;top: 54%;z-index: 99;width: 20%;" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/6f4a3f765a1b41adffec6aa4fd30871d.png" alt="">
  </div>-->
  <order-red-package
      v-if="advise"
      @close="close"
      :src="require('@/assets/img/activity/invitation.png')"
  ></order-red-package>
  <banner :list="list"></banner>
  <ul class="nav-wapper">
    <li>
      <router-link
        :to="{
          name: 'ProductCategory',
        }"
      >
        <div>
          <!-- <img src="https://www.bzwz.com/static/m/images/icon_bw.png" alt="" /> -->
          <img src="@/assets/img/leftNav/icon16.png" alt="">
        </div>
        标准物质
      </router-link>
    </li>
    <li>
      <router-link
              :to="{
          name: 'customize',
        }"
      >
        <div>
          <img
                  style="width: 30px; height: 30px"
                  src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/m-home-dz20201027.png"
                  alt=""
          />
        </div>
        标物定制
      </router-link>
    </li>
    <!-- <li>
      <router-link
        :to="{
          name: 'product',
          query: {
            type: '1',
          },
        }"
      >
        <div>
          <img src="https://www.bzwz.com/static/m/images/icon_yp.png" alt="" />
        </div>
        菌种细胞
      </router-link>
    </li> -->
    <!--<li>
      <router-link
        :to="{
          name: 'standard',
        }"
      >
        <div>
          <img
            style="width: 30px; height: 30px"
            src="@/assets/img/gb.png"
            alt=""
          />
        </div>
        标准查询
      </router-link>
    </li>-->
    <li>
      <router-link
        :to="{
          name: 'Topic',
        }"
      >
        <div>
          <img
            style="width: 30px; height: 30px"
            src="@/assets/img/leftNav/icon_fatie.png"
            alt=""
          />
        </div>
        交流区
      </router-link>
    </li>

    <li>
      <router-link :to="{ name: 'News' }">
        <div>
          <img
            style="width: 26.512px; height: 27.488px"
            src="@/assets/img/leftNav/icon12.png"
            alt=""
          />
          <!-- src="https://www.bzwz.com/static/m/images/icon12.png" -->
        </div>
        新闻资讯
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'BnccCategory'}">
        <div>
          <img
                  style="width: 24px; height: 28px"
                  src="https://beinabncc.oss-cn-beijing.aliyuncs.com/file//yp20210419.png"
          />
        </div>
        微生物质控品
      </router-link>
    </li>
    <li>
      <router-link :to="{name: 'specialProCategory'}">
        <div>
          <svg
            style="display: block; margin: 0 auto"
            t="1612256353934"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1975"
            width="32"
            height="32"
          >
            <path
              d="M409.38666667 570.02666667c-72.42666667 0-131.30666667-58.88-131.30666667-131.30666667 0-72.42666667 58.88-131.30666667 131.30666667-131.30666667 72.42666667 0 131.30666667 58.88 131.30666666 131.30666667 0 72.42666667-58.98666667 131.30666667-131.30666666 131.30666667z m0-219.94666667c-48.85333333 0-88.64 39.78666667-88.64 88.64s39.78666667 88.64 88.64 88.64 88.64-39.78666667 88.64-88.64-39.78666667-88.64-88.64-88.64zM833.28 454.29333333H643.73333333c-11.73333333 0-21.33333333-9.6-21.33333333-21.33333333s9.6-21.33333333 21.33333333-21.33333333h189.54666667c11.73333333 0 21.33333333 9.6 21.33333333 21.33333333s-9.6 21.33333333-21.33333333 21.33333333zM833.28 556.37333333H643.84c-11.73333333 0-21.33333333-9.6-21.33333333-21.33333333s9.6-21.33333333 21.33333333-21.33333333h189.54666667c11.73333333 0 21.33333333 9.6 21.33333333 21.33333333-0.10666667 11.73333333-9.70666667 21.33333333-21.44 21.33333333z"
              p-id="1976"
              fill="#df0024"
            ></path>
            <path
              d="M726.72 685.22666667l-138.34666667 221.86666666c-6.18666667 9.92-19.41333333 13.01333333-29.44 6.82666667-9.92-6.18666667-13.01333333-19.41333333-6.82666666-29.44l138.34666666-221.86666667c6.18666667-9.92 19.41333333-13.01333333 29.44-6.82666666 10.02666667 6.18666667 13.01333333 19.41333333 6.82666667 29.44z"
              p-id="1977"
              fill="#df0024"
            ></path>
            <path
              d="M909.01333333 94.29333333H114.45333333c-18.77333333 0-34.13333333 15.36-34.13333333 34.13333334v766.82666666c0 18.77333333 15.36 34.13333333 34.13333333 34.13333334h71.36v-42.66666667h-62.82666666V136.96h777.49333333v749.76h-67.73333333v42.66666667h76.26666666c18.77333333 0 34.13333333-15.36 34.13333334-34.13333334V128.42666667c0-18.77333333-15.36-34.13333333-34.13333334-34.13333334z"
              p-id="1978"
              fill="#df0024"
            ></path>
            <path
              d="M276.58666667 755.73333333c0-68.16 55.46666667-123.52 123.52-123.52h17.70666666c68.16 0 123.52 55.46666667 123.52 123.52l0.96 152c0.53333333 11.2 9.81333333 20.05333333 20.8 20.26666667 11.30666667 0.21333333 21.01333333-8.74666667 21.54666667-20.26666667-0.21333333-50.66666667-0.42666667-101.33333333-0.74666667-152 0-91.62666667-74.56-166.18666667-166.18666666-166.18666666H400c-91.62666667 0-166.18666667 74.56-166.18666667 166.18666666 0.10666667 50.66666667 0.32 101.33333333 0.42666667 152-0.21333333 11.52 9.06666667 21.22666667 20.58666667 21.33333334 11.52 0.10666667 21.01333333-9.28 21.12-20.8l0.64-152.53333334z"
              p-id="1979"
              fill="#df0024"
            ></path>
          </svg>
        </div>
        专项标物
      </router-link>
    </li>
    <!--<li>
      <router-link :to="{ name: 'Topic' }">
        <div>
          <img
            style="width: 24px; height: 28px"
            src="@/assets/img/leftNav/icon_fatie.png"
            alt=""
          />
          &lt;!&ndash; src="https://www.bzwz.com/static/m/images/icon_fatie.png" &ndash;&gt;
        </div>
        交流区
      </router-link>
    </li>-->

    <li>
      <router-link :to="{ name: 'Course' }">
        <div>
          <img
                  style="width: 25.987px; height: 25.987px"
                  src="@/assets/img/leftNav/icon13.png"
                  alt=""
          />
        </div>
        <!-- src="https://www.bzwz.com/static/m/images/icon13.png" -->
        直播间
      </router-link>
    </li>

    <li>
      <router-link :to="{ name: 'About' }">
        <div>
          <img
            style="width: 27.488px; height: 26.512px"
            src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/m-home-weiye20201027.png"
            alt=""
          />
        </div>
        关于我们
      </router-link>
    </li>
  </ul>
  <div class="hot-wapper">
    <div class="hot-title">
      标物推荐
      <router-link
        class="learn-more"
        :to="{
          name: 'product',
        }"
      >
        更多
        <!-- <img src="https://www.bzwz.com/static/m/images/icon20.png" alt="" /> -->
        <img src="@/assets/img/index/icon20.png" alt="" />
      </router-link>
    </div>
    <div class="hot-content">
      <div class="swiper-container recommend-container" id="hot-swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="item in hotSaleList"
            :key="item.id"
          >
            <router-link
              :to="{
                name: 'productdetail',
                params: { id: item.id, classId: item.classID },
              }"
              class="hot-container"
            >
              <img :src="item.pic" :alt="item.cnname" />

              <div class="hot-no">{{ item.catno }}</div>
              <div class="hot-name">{{ item.cnname }}</div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="skeleton-container">
        <div v-if="!hotSaleList" class="skeleton-wrapper skeleton">
          <div class="skeleton-slide" v-for="i in 3" :key="i">
            <a href="javascript:;" class="hot-container">
              <div class="hot-img skeleton-bg"></div>
              <div class="hot-no skeleton-bg"></div>
              <div class="hot-name skeleton-bg"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <menu-tab class="menu" :menu="menu" @change="selectMenu"></menu-tab>
  <list
    url="/M/Server/GetLastKetang"
    :filterFunc="filterFunc"
    :show="currentMenuItem == '直播'"
  >
    <template v-slot:default="data">
      <class-item :course="data"></class-item>
    </template>
  </list>
  <list url="/M/Server/GetLastNews" :show="currentMenuItem == '新闻'">
    <template v-slot:default="data">
      <news-item :newData="data"></news-item>
    </template>
  </list>
  <list url="/M/Server/GetLastTopic" :show="currentMenuItem == '帖子'">
    <template v-slot:default="data">
      <router-link :to="{ name: 'Interest' }" class="post-header">
        <div class="left">您可能感兴趣的人</div>
        <div class="post-header-right">
          <!-- <img src="https://www.bzwz.com/static/m/images/pic5.jpg" alt="" /> -->
          <img src="@/assets/img/topItem/pic5.jpg" alt="" />
          <img
            class="post-second-user"
            src="@/assets/img/topItem/pic6.jpg"
            alt=""
          />
          <!-- src="https://www.bzwz.com/static/m/images/pic6.jpg" -->
          <img
            class="post-third-user"
            src="@/assets/img/topItem/pic7.jpg"
            alt=""
          />
          <!-- src="https://www.bzwz.com/static/m/images/pic7.jpg" -->
        </div>
      </router-link>
      <topic-item :topicData="data"></topic-item>
    </template>
  </list>

  <list
    url="/M/Server/GetProductLastbw"
    :filterFunc="productMap"
    :show="currentMenuItem == '热销'"
  >
    <template v-slot:default="data">
      <product-card :list="data" name="productdetail" tid="0"></product-card>
    </template>
  </list>

</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted,ref, nextTick, watch } from "vue";

import banner from "@/components/common/Swiper.vue";
import list from "@/components/common/list.vue";

import newsItem from "@/components/common/NewsItem.vue";
import TopicItem from "@/components/common/TopicItem.vue";
import ClassItem from "@/components/common/ClassItem.vue";
import productCard from "@/components/common/productCard.vue";

import MenuTab from "@/components/common/MenuTab.vue";
import Toast from "@/components/UI/Toast/";
//ts
import { productMap } from "@/hooks/useProduct";
import { useRouter, useRoute } from "vue-router";
//  utils
import api from "@/api/axios";
import Swiper from "swiper";
import OrderRedPackage from "@/components/common/OrderRedPackage.vue"

export default defineComponent({
  name: "Index",
  components: {
    banner,
    list,
    newsItem,
    TopicItem,
    ClassItem,
    MenuTab,
    productCard,
    OrderRedPackage
  },

  setup() {
    const advise = ref(true)

    const router = useRouter();
    const route = useRoute();
    let timer: any = null;
    //onMounted挂载完成
    onMounted(() => {
      nextTick(() => {
        const swiper = new Swiper("#hot-swiper", {
          slidesPerView: 3,
          initialSlide: 0,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
        });
      });
    });
    // //onUnmounted 在页面销毁前销毁定时器
    // onUnmounted(() => {
    //   //销毁当前页面的定时器
    //   console.log("sdfsdfs")
    //   advise.value = false;
    //   clearInterval(timer);
    // });

    function filterFunc(data: any) {
      if (data.fenlei == 228) {
        const teacherNames =
          (data.teacherNames && data.teacherNames.split(",")) || [];
        const teacherIds =
          (data.teacherIds && data.teacherIds.split(",")) || [];
        teacherNames.pop();
        teacherIds.pop();
        data.teacherNames = teacherNames;
        data.teacherIds = teacherIds;
      }
      return data;
    }

    const list = ref(null);
    const hotSaleList = ref(null);
    api.get("/M").then((res): void => {
      list.value = res.data.obj.listimg;
      hotSaleList.value = res.data.obj.hotSaleList;
    });
    //  切换列表菜单
    type Menu = "新闻" | "帖子" | "直播" | "热销";
    const menu: Menu[] = ["直播", "新闻", "帖子", "热销"];
    const currentMenuItem = ref(menu[0]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }

    function close() {
      advise.value = false;

    }


    watch(
      () => {
        return list.value
      },
      () => {
        timer = setTimeout(() => {
          advise.value = false
        }, 5000)
      }
    )
    if(localStorage.getItem('WX')){
      setTimeout(function () {
        location.reload();
      },1000)
    }
    localStorage.removeItem('WX')


    return {
      list,
      hotSaleList,
      menu,
      selectMenu,
      currentMenuItem,
      productMap,
      filterFunc,
      // jumpRed,
      advise,
      close
    };
  },
});
</script>

<style lang="scss" scoped>
//  nav

.nav-wapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding-bottom: 20px;
  border-bottom: 6px solid #f4f4f4;
  li {
    width: 25%;
    margin-top: 9px;
    float: left;
    width: 25%;
    height: 70px;
    img {
      width: 20px;
      height: 30px;
      display: block;
      margin: 0 auto;
    }
    a {
      display: block;
      width: 100%;
      height: 40px;
      text-align: center;
      font-size: 14px;
      color: #000;
    }
    div {
      height: 40px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-bottom: 7px;
    }
  }
}

//  hot product

.hot-title {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #f4f4f4;
  font-size: 15px;
  padding-left: 21px;
  box-sizing: border-box;
  position: relative;
  line-height: 48px;
  margin-bottom: 12px;
  color: #333;
  &::after {
    position: absolute;
    content: "";
    left: 10.5px;
    top: 50%;
    width: 2.5px;
    height: 13px;
    transform: translateY(-40%);
    background-color: #df0024;
  }
  .learn-more {
    position: absolute;
    right: 10.5px;
    top: 0;
    font-size: 14px;
    color: #666;
    img {
      vertical-align: middle;
      position: relative;
      top: -2px;
      width: 5px;
      height: 10px;
    }
  }
}
.hot-content {
  width: 100%;
  overflow: hidden;

  box-sizing: border-box;
  .recommend-container {
    padding-left: 9px;
  }
  .hot-container {
    width: 110px;
    display: block;
    img {
      width: 110px;
      height: 110px;
      display: block;
      margin-bottom: 8px;
    }
    .hot-no {
      width: 100%;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #f29773;
      font-size: 12px;
    }
    .hot-name {
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 5px;
      color: #444444;
      font-size: 14px;
    }
  }
}
.menu-nav-wapper {
  margin-top: 20px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    border-top: 6px solid #f4f4f4;
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}
.skeleton-wrapper {
  display: flex;
  justify-content: space-around;
}
.skeleton {
  .hot-container {
    .hot-img {
      width: 110px;
      height: 110px;
      display: block;
      margin-bottom: 8px;
    }
    .hot-no {
      width: 100%;
      height: 17px;
    }
    .hot-name {
      width: 100%;
      height: 20px;
    }
  }
  .skeleton-bg {
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 74.5%, 0.2) 25%,
      hsla(0, 0%, 50.6%, 0.24) 37%,
      hsla(0, 0%, 74.5%, 0.2) 63%
    );
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
  }
}

.post-header {
  height: 50px;
  display: block;
  line-height: 50px;
  border-bottom: 1px solid #f4f4f4;
}

.post-header .left {
  float: left;
  color: #666666;
  font-size: 16px;
  padding-left: 20px;
}

.post-header .post-header-right {
  float: right;
}

.post-header .post-header-right img {
  position: relative;

  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.post-second-user {
  left: -5px;
}

.post-third-user {
  left: -15px;
}

</style>
